import React from 'react';

const AppFooter = (props) => {
    return (
        <div className="layout-footer">
            <div className="footer-logo-container">
                <img id="footer-logo" src={`assets/highschools/${props.schoolTheme}/layout/images/logo-${props.colorScheme}.png`} alt="atlantis-layout" />
                <span className="app-name">{props.schoolDetails.displayName}</span>
            </div>
        </div>
    );
};

export default AppFooter;
