import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';

import AuthService from '../service/AuthService';
const Auth = new AuthService();

export const RegisterConfirm = (props) => {
    const navigate = useNavigate();
	const { registerHash } = useParams();
	const toast = useRef(null);
	const [codeLoaded, setCodeLoaded] = useState(false);
	const [userDetails, setUserDetails] = useState({
		email: '',
		firstname: '',
		lastname: '',
		password: '',
		password2: ''
	});

	useEffect(() => {
        fncGetDetails()
    }, []);

	let fncGetDetails = async () => {
		let registerInfo = await Auth.getRegisterInfo(registerHash)
		if (registerInfo.success !== true) navigate('/register');
		fncUpdateUserDetails('email', registerInfo.email)
		setCodeLoaded(true)
	}

	let fncUpdateUserDetails = (key,value) => {
		let _userDetails = {...userDetails}
		_userDetails[key] = value
		setUserDetails(_userDetails)
	}

	let fncSubmit = async () => {
		let blnValidationError = false
		if (userDetails.firstname === '') blnValidationError = true
		if (userDetails.lastname === '') blnValidationError = true
		if (userDetails.password === '') blnValidationError = true
		if (userDetails.password.length < 8) blnValidationError = true
		if (userDetails.password !== userDetails.password2) blnValidationError = true

		if (blnValidationError) {
			toast.current.show({severity:'error', summary: 'Validation Error', detail:'All fields are required and the password fields must match.', life: 2000});	
			return
		}

		let registerResults = await Auth.registerConfirm(userDetails)
		
	}

    return (
		<>
			{codeLoaded && 
				<div className="exception-body notfound">
					<Toast ref={toast} />
					<div className="exception-panel">
						<h3>Register</h3>
						<p>Fill in the following form to complete your registration.</p>
					</div>
					<div className="card mt-4">
						<div className="p-fluid formgrid grid">
							<div className="field col-12">
								<label htmlFor="email">Email</label>
								<InputText id="email" disabled value={userDetails.email} type="text" />
							</div>
							<div className="field col-12 md:col-6">
								<label htmlFor="firstname">First Name</label>
								<InputText id="firstname" type="text" value={userDetails.firstname} onChange={(e) => fncUpdateUserDetails('firstname',e.target.value)} />
							</div>
							<div className="field col-12 md:col-6">
								<label htmlFor="lastname">Last Name</label>
								<InputText id="lastname" type="text" value={userDetails.lastname} onChange={(e) => fncUpdateUserDetails('lastname',e.target.value)} />
							</div>
							<div className="field col-12 md:col-6">
								<label htmlFor="password">Password</label>
								<InputText id="password" type="password" value={userDetails.password} onChange={(e) => fncUpdateUserDetails('password',e.target.value)} />
								<small>Must be at least 8 characters in length.</small>
							</div>
							<div className="field col-12 md:col-6">
								<label htmlFor="password2">Verify Password</label>
								<InputText id="password2" type="password" value={userDetails.password2} onChange={(e) => fncUpdateUserDetails('password2',e.target.value)} />
							</div>
							<div className="field col-12">
								<Button type="button" label="Register" onClick={fncSubmit}></Button>
							</div>
						</div>
					</div>
					
					
					<div className="exception-footer">
						<img src={`assets/highschools/` + props.schoolTheme + `/layout/images/logo-${props.colorScheme === 'light' ? 'dark' : 'light'}.png`} className="exception-logo mr-2" alt="exception-logo" />
						{props.schoolDetails.displayName} Sports
					</div>
				</div>
			}
		</>
    );
};
