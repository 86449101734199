import React from 'react';

let TeamSchedule = (props) => {

	if (!props.schedule) return null

	return (
		<div className="grid">
			{props.schedule.map((cur,index) => {
				return (
					<React.Fragment key={"scheduleItem-" + index}>
						<div className="col-8">
							{cur.team}
						</div>
						<div className="col-4">
							{cur.score}
						</div>
					</React.Fragment>
				)
			})}
		</div>
	)
	
	
}

export default TeamSchedule;