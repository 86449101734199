import React, { useState, useRef }  from 'react';
import { useNavigate } from 'react-router-dom';

import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';

import { emailRegex } from '../utilities/regex'
import AuthService from '../service/AuthService';
import SEO  from '../components/SEO'
const Auth = new AuthService();

export const  Register = (props) => {
    const navigate = useNavigate();
	const toast = useRef(null);
	const [email, setEmail] = useState('');
	const [confirm, setConfirm] = useState(false);

	let fncSubmit = async () => {
		if (email.match(emailRegex)) {
			let registerResults = await Auth.register(email)
			
			if (registerResults.success) setConfirm(true)
			else {
				toast.current.show({severity:'error', summary: 'Unable to register', detail:registerResults.message, life: 2000});
			}
		} else {
			toast.current.show({severity:'warn', summary: 'Invalid Email', detail:'Please enter a valid email.', life: 2000});
		}	
	}

	let fncLogin = () => {
		navigate('/login');
	}
	
	return (
        <div className="login-body">
			 <SEO 
			 	title={props.schoolDetails.abbreviation + " Register"}
				description={props.schoolDetails.displayName + " Register Page"} 
			 />	
			<Toast ref={toast} />
            <div className="login-image">
                <img src={`assets/highschools/` + props.schoolTheme + `/layout/images/pages/login-${props.colorScheme === 'light' ? 'ondark' : 'onlight'}.png`} alt="atlantis" />
            </div>
            <div className="login-panel p-fluid">
                <div className="flex flex-column">
                    <div className="flex align-items-center mb-6 logo-container">
						<img src={`assets/highschools/` + props.schoolTheme + `/layout/images/logo-${props.colorScheme === 'light' ? 'dark' : 'light'}.png`} className="register-logo mr-2" alt="exception-logo" />
						{props.schoolDetails.displayName}
                    </div>
					{!confirm && 
						<>
							<div className="button-container mb-4">
								<span>
									Enter a valid email to start the sign-up process
								</span>
							</div>
							<div className="form-container">
								<span className="p-input-icon-left">
									<i className="pi pi-envelope"></i>
									<InputText value={email} onChange={(e) => setEmail(e.target.value)} />
								</span>
							
							</div>
							
							<div className="button-container">
								<Button type="button" label="Sign-up" onClick={(e) => {fncSubmit(e)}}></Button>
								<span>
									Already have an account?<button className="p-link" onClick={fncLogin}>Login here</button>
								</span>
							</div>
							
						</>
					}
					{confirm && 
						<div className="flex flex-column confirmRegistration">
							<div>
								<i className="pi pi-envelope" ></i>
							</div>
							<div>
								<h2>Email Confirmation</h2>
							</div>
							<div>
								We have sent email to <span className="boldText">{email}</span> to confirm your email address. Please check your email and use the enclosed link to finish registering.
							</div>


						</div>
					}
                </div>

                <div className="login-footer flex align-items-center">
                    <div className="flex align-items-center login-footer-logo-container">
						<img src={`assets/highschools/` + props.schoolTheme + `/layout/images/logo-gray.png`} className="footer-logo mr-2" alt="exception-logo" />
						{props.schoolDetails.displayName}
                    </div>
                    <span>Copyright 2023</span>
                </div>
            </div>
        </div>
    );
};