import React, { useState } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { classNames } from 'primereact/utils';
import { BreadCrumb } from 'primereact/breadcrumb';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';

const AppBreadcrumb = (props) => {
    const [search, setSearch] = useState('');
    const location = useLocation();

    const activeRoute = props.routes.filter((route) => {
        let arrPath = location.pathname.toLowerCase().replace(/\s/g, '').slice(1).split('/')

		let strPath = arrPath[arrPath.length - 1].replace(/-/g, '')
		if (arrPath.length === 2 ) {
			strPath = arrPath[0] + arrPath[1].replace(/-/g, '')
			console.log(strPath)
		} 

		return route.label.replace(/\s/g, '').toLowerCase() === strPath;

		
    });

    let items;
    if (location.pathname === '/') {
        items = [{ label: 'Dashboard' }];
    } else if (!activeRoute.length) {
        items = [{ label: '' }, { label: '' }];
    } else {
        items = [ { label: activeRoute[0].label }];
    }

	const home = { icon: 'pi pi-home', command:()=>{ window.location.hash="/"; } }

    const isStatic = () => {
        return props.menuMode === 'static';
    };

	return (
        <div className="layout-breadcrumb-container">
            <div className="layout-breadcrumb-left-items">
                {isStatic() && (
                    <button className="menu-button p-link" onClick={props.onMenuButtonClick}>
                        <i className="pi pi-bars"></i>
                    </button>
                )}

                <BreadCrumb model={items} className="layout-breadcrumb" home={home}/>
            </div>
			<div className="layout-breadcrumb-right-items">
				<span className="layout-rightmenu-button-desktop">
					<Link to='/help'>
                    	<Button label="Help" icon="pi pi-question-circle" className="layout-rightmenu-button"></Button>
					</Link>
                </span>
			</div>
        </div>
    );
};

export default AppBreadcrumb;
