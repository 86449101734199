import React from 'react';
import SEO  from '../components/SEO'

const EmptyPage = (props) => {
    return (
        <div className="grid">
			<SEO 
			 	title={props.schoolDetails.abbreviation + " Empty Page"}
				description={props.schoolDetails.displayName + " Empty Page"} 
			 />	
            <div className="col-12">
                <div className="card">
                    <h5>Empty Page</h5>
                    <p>This is your empty page template to start building beautiful applications.</p>
                </div>
            </div>
        </div>
    );
};

export default EmptyPage;
