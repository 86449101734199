import React from 'react';
import { Accordion, AccordionTab } from 'primereact/accordion';
import SEO  from '../components/SEO'

const Help = (props) => {
    return (
        <div className="grid help-page">
			<SEO 
			 	title={props.schoolDetails.abbreviation + " Help Page"}
				description={props.schoolDetails.displayName + " Help Page"} 
			 />	
            <div className="col-12">
                <h6>General</h6>
                <Accordion activeIndex={0}>
                    <AccordionTab header="How to get VHS tapes uploaded to YouTube">
                        <p>
                            Coming Soon...
                        </p>
                    </AccordionTab>
                    
                </Accordion>

                <h6>FAQ</h6>
                <Accordion activeIndex={0}>
                    <AccordionTab header="What is this site?">
                        <p>
                            Coming Soon...
                        </p>
                    </AccordionTab>
                </Accordion>
            </div>
        </div>
    );
};

export default Help;
