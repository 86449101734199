import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AppMenu from './AppMenu';
import { classNames } from 'primereact/utils';
import { Button } from 'primereact/button';

import ComingSoon from './modals/comingSoon'

const AppTopbar = (props) => {
    const navigate = useNavigate();
	const [comingSoonDisplay, setComingSoonDisplay] = useState(false);
	
	const onTopbarSubItemClick = (event) => {
        event.preventDefault();
    };

	const fncLogin = () => {

		setComingSoonDisplay(true)
		//navigate('/login')
	}

    

    return (
        <>
            <div className="layout-topbar">
                <div className="layout-topbar-left">
                    <button className="topbar-menu-button p-link" onClick={props.onMenuButtonClick}>
                        <i className="pi pi-bars"></i>
                    </button>

                    <button className="logo p-link" onClick={() => navigate('/')}>
						<img src={`assets/highschools/${props.schoolTheme}/layout/images/logo-${props.colorScheme}.png`} alt="logo" onClick={() => navigate('/')} />
                    </button>

                    <button className="p-link pl-2" onClick={() => navigate('/')}>
                        {props.schoolDetails.displayName}
                    </button>
                </div>

                <AppMenu
                    model={props.items}
                    menuMode={props.menuMode}
                    colorScheme={props.colorScheme}
                    menuActive={props.menuActive}
                    activeInlineProfile={props.activeInlineProfile}
                    onSidebarMouseOver={props.onSidebarMouseOver}
                    onSidebarMouseLeave={props.onSidebarMouseLeave}
                    toggleMenu={props.onToggleMenu}
                    onChangeActiveInlineMenu={props.onChangeActiveInlineMenu}
                    onMenuClick={props.onMenuClick}
                    onRootMenuItemClick={props.onRootMenuItemClick}
                    onMenuItemClick={props.onMenuItemClick}
					schoolTheme={props.schoolTheme}
					schoolDetails={props.schoolDetails}
                />
				{props.isLoggedIn && 
					<div className="layout-topbar-right">
						<ul className="layout-topbar-right-items">
							<li id="profile" className={classNames('profile-item', { 'active-topmenuitem': props.topbarMenuActive })}>
								<button className="p-link" onClick={props.onTopbarItemClick}>
									<img src={"assets/highschools/" + props.schoolTheme + "/layout/images/profile-image.png"} alt="profile" />
								</button>

								<ul className="fadeInDown">
									<li role="menuitem">
										<button className="p-link" onClick={onTopbarSubItemClick}>
											<i className="pi pi-fw pi-user"></i>
											<span>Profile</span>
										</button>
									</li>
									<li role="menuitem">
										<button className="p-link" onClick={onTopbarSubItemClick}>
											<i className="pi pi-fw pi-cog"></i>
											<span>Settings</span>
										</button>
									</li>
									<li role="menuitem">
										<button className="p-link" onClick={onTopbarSubItemClick}>
											<i className="pi pi-fw pi-sign-out"></i>
											<span>Logout</span>
										</button>
									</li>
								</ul>
							</li>
						</ul>
					</div>
				}
				{!props.isLoggedIn && 
					<div className="ml-2">
						<Button type="button" label="Login" style={{width:"90%"}} onClick={fncLogin}></Button>
					</div>
					
				}
				<ComingSoon 
					comingSoonDisplay={comingSoonDisplay} 
					setComingSoonDisplay={setComingSoonDisplay}
					comingSoonType="login"
				/>
            </div>
        </>
    );
};

export default AppTopbar;
