import React, { useEffect, useState } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import axios from 'axios';
import App from './App';
import { Login } from './pages/Login';
import { Register } from './pages/Register'
import { RegisterConfirm } from './pages/RegisterConfirm'
import { Error } from './pages/Error';
import { NotFound } from './pages/NotFound';
import { Access } from './pages/Access';

const AppWrapper = (props) => {
    const [colorScheme, setColorScheme] = useState('dark');
	const [schoolTheme, setSchoolTheme] = useState('linesville');
	const [schoolDetails, setSchoolDetails] = useState({});
	const [schoolLoaded, setSchoolLoaded] = useState(false);

    let location = useLocation();


	useEffect(() => {
        if (window.location.host.split('.')[0] === 'cash') {
			onSchoolChange('conneaut-area-senior-high')
		} else onSchoolChange('linesville')
		
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);

	console.log('In Wrapper')

	useEffect(() => {
		// switch layout css based on school
		const layoutLink = document.getElementById('layout-css');
        const layoutHref = 'assets/highschools/' + schoolTheme + '/layout/css/layout-' + colorScheme + '.css';
        replaceLink(layoutLink, layoutHref);
		
		// switch theme css based on school
		const themeLink = document.getElementById('theme-css');
        const themeHref = 'assets/highschools/' + schoolTheme + '/theme/theme-' + colorScheme + '.css';
        replaceLink(themeLink, themeHref);
		
		// change the favicon based on school
		let favIconLink = document.getElementById('favIcon');
		const favIconHref = 'assets/highschools/' + schoolTheme + '/favicon.ico'
		favIconLink.setAttribute("href",favIconHref);

		// load the JSON details for the school
		const schoolJSON = 'assets/highschools/' + schoolTheme + '/details.json'
		fetch(schoolJSON)
			.then(res => res.json())
			.then(res => {
				setSchoolDetails(res)
			})
			.catch(_ => console.log(_));
			setTimeout(() => {
				setSchoolLoaded(true)
			}, 100)
    }, [schoolTheme, colorScheme]);

	

    const onColorSchemeChange = (scheme) => {
        changeStyleSheetUrl('layout-css', 'layout-' + scheme + '.css', 1);
        changeStyleSheetUrl('theme-css', 'theme-' + scheme + '.css', 1);
        setColorScheme(scheme);
    };

    const changeStyleSheetUrl = (id, value, from) => {
        const element = document.getElementById(id);
        const urlTokens = element.getAttribute('href').split('/');

        if (from === 1) {
            // which function invoked this function - change scheme
            urlTokens[urlTokens.length - 1] = value;
        } else if (from === 2) {
            // which function invoked this function - change color
            urlTokens[urlTokens.length - 2] = value;
        }

        const newURL = urlTokens.join('/');

        replaceLink(element, newURL);
    };

    const onSchoolChange = (schoolTheme) => {
		axios.defaults.headers.common['school'] = schoolTheme;
		setSchoolTheme(schoolTheme);
    };
	
    const replaceLink = (linkElement, href, callback) => {
        const id = linkElement.getAttribute('id');
        const cloneLinkElement = linkElement.cloneNode(true);

		cloneLinkElement.setAttribute('href', href);
        cloneLinkElement.setAttribute('id', id + '-clone');

        linkElement.parentNode.insertBefore(cloneLinkElement, linkElement.nextSibling);

        cloneLinkElement.addEventListener('load', () => {
            linkElement.remove();
            const _linkElement = document.getElementById(id);
            _linkElement && _linkElement.remove();
            cloneLinkElement.setAttribute('id', id);

            if (callback) {
                callback();
            }
        });
    };

    return (
		<>
			{schoolLoaded && 
				<Routes>
					<Route path="/login" element={<Login colorScheme={colorScheme} schoolTheme={schoolTheme} schoolDetails={schoolDetails} />} />
					<Route path="/register" element={<Register colorScheme={colorScheme} schoolTheme={schoolTheme} schoolDetails={schoolDetails} />} />
					<Route path="/register/:registerHash" element={<RegisterConfirm colorScheme={colorScheme} schoolTheme={schoolTheme} schoolDetails={schoolDetails} />} />
					<Route path="/error" element={<Error colorScheme={colorScheme} schoolTheme={schoolTheme} schoolDetails={schoolDetails} />} />
					<Route path="/notfound" element={<NotFound colorScheme={colorScheme} schoolTheme={schoolTheme} schoolDetails={schoolDetails} />} />
					<Route path="/access" element={<Access colorScheme={colorScheme} schoolTheme={schoolTheme} schoolDetails={schoolDetails} />} />
					<Route path="*" element={<App colorScheme={colorScheme} onColorSchemeChange={onColorSchemeChange}
						onSchoolChange={onSchoolChange} schoolTheme={schoolTheme} schoolDetails={schoolDetails} />} 
					/>
				</Routes>
			}
		</>
    );
};

export default AppWrapper;
