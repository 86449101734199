import axios from 'axios';
import { GET_YEARS, GET_SPORTS, GET_YEARS_BY_SPORT, GET_SPORTS_BY_YEAR, GET_TEAM } from '../constants/URLS';


export default class PagesService {
	getYears = async () => {
		try {
			let results = await axios({
				method: 'get',
				url: GET_YEARS,
			})

			if (!results.data.success){
				return []
			} else {
				return results.data.results
			}
		} catch (err)  {
			return []
		}
	}

	getSports = async () => {
		try {
			let results = await axios({
				method: 'get',
				url: GET_SPORTS,
			})

			if (!results.data.success){
				return []
			} else {
				return results.data.results
			}
		} catch (err)  {
			return []
		}
	}

	getYearsBySport = async (sport) => {
		try {
			let results = await axios({
				method: 'get',
				url: GET_YEARS_BY_SPORT + '/' + sport,
			})

			if (!results.data.success){
				return []
			} else {
				return results.data.results
			}
		} catch (err)  {
			return []
		}
	}

	getSportsByYear = async (year) => {
		try {
			let results = await axios({
				method: 'get',
				url: GET_SPORTS_BY_YEAR + '/' + year,
			})

			if (!results.data.success){
				return []
			} else {
				return results.data.results
			}
		} catch (err)  {
			return []
		}
	}

	getTeam = async (year, sport) => {
		try {
			let results = await axios({
				method: 'get',
				url: GET_TEAM + '/' + year + '/' + sport,
			})

			if (!results.data.success){
				return []
			} else {
				return results.data.results
			}
		} catch (err)  {
			return []
		}
	}
	
}