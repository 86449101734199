import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import YearPage from './Year'
import SportPage from './Sport'
import TeamPage from './Team'

const PageType = (props) => {
    const { sportOrYear,  sportOrYear2} = useParams();
	const [ pageType, setPageType] = useState(null);
	const [ year, setYear] = useState(null);
	const [ sport, setSport] = useState(null);

	useEffect(() => {
        let _year = null
		let _sport = null
		if (Number.isInteger(parseInt(sportOrYear))) {
			_year = sportOrYear
		} else {
			_sport = sportOrYear
		}

		if (sportOrYear2) {
			if (Number.isInteger(parseInt(sportOrYear2))) {
				_year = sportOrYear2
			} else {
				_sport = sportOrYear2
			}
		}

		if (_year && _sport) setPageType('team')
		else if (_sport) setPageType('sport')
		else if (_year) setPageType('year')

		setSport(_sport)
		setYear(_year)
    }, [sportOrYear, sportOrYear2]);

	return (
        <>
			{pageType === 'team' && <TeamPage sport={sport} year={year} schoolTheme={props.schoolTheme}  schoolDetails={props.schoolDetails} />}
			{pageType === 'sport' && <SportPage sport={sport} schoolTheme={props.schoolTheme}  schoolDetails={props.schoolDetails} />}
			{pageType === 'year' && <YearPage year={year} schoolTheme={props.schoolTheme}  schoolDetails={props.schoolDetails} />}
		</>
    );
};

export default PageType;
