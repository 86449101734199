import React, { useState, useRef}  from 'react';
import { useNavigate } from 'react-router-dom';

import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';

import { emailRegex } from '../utilities/regex'
import AuthService from '../service/AuthService';
import SEO  from '../components/SEO'
const Auth = new AuthService();

export const Login = (props) => {
    const navigate = useNavigate();
	const toast = useRef(null);
	const [username, setUsername] = useState('');
	const [password, setPassword] = useState('');
	const [showError, setShowError] = useState(false);

	let fncSubmit = async () => {
		try {
			if (username.match(emailRegex) && password !== '') {
				let results = await Auth.login(username,password,props.schoolTheme)
				if (results) navigate('/');
				else toast.current.show({severity:'error', summary: 'Login Error', detail:'The email/password combination was incorrect', life: 2000});	
			} else {
				toast.current.show({severity:'warn', summary: 'Required Fields', detail:'Please enter a valid email and your password.', life: 2000});
			}
		} catch (err) {
			toast.current.show({severity:'error', summary: 'Login Error', detail:'Unable to log in at this time.', life: 2000});	
		}
	}

	let fncRegister = () => {
		navigate('/register');
	}
	
	return (
        <div className="login-body">
			 <SEO 
			 	title={props.schoolDetails.abbreviation + " Login"}
				description={props.schoolDetails.displayName + " Login Page"} 
			 />			
			<Toast ref={toast} />
            <div className="login-image">
                <img src={`assets/highschools/` + props.schoolTheme + `/layout/images/pages/login-${props.colorScheme === 'light' ? 'ondark' : 'onlight'}.png`} alt="atlantis" />
            </div>
            <div className="login-panel p-fluid">
                <div className="flex flex-column">
                    <div className="flex align-items-center mb-6 logo-container">
						<img src={`assets/highschools/` + props.schoolTheme + `/layout/images/logo-${props.colorScheme === 'light' ? 'dark' : 'light'}.png`} className="register-logo mr-2" alt="logo" />
						{props.schoolDetails.displayName}
                    </div>
                    <div className="form-container">
                        <span className="p-input-icon-left">
                            <i className="pi pi-envelope"></i>
                            <InputText value={username} onChange={(e) => setUsername(e.target.value)} />
                        </span>
                        <span className="p-input-icon-left">
                            <i className="pi pi-key"></i>
                            <InputText type="password" value={password} onChange={(e) => setPassword(e.target.value)}/>
                        </span>
                        <button className="flex p-link">Forgot your password?</button>
                    </div>
					{showError && 
							<div className="col-12" style={{"color":"red"}}>
								Unable to log in.
							</div>
						}
                    <div className="button-container">
                        <Button type="button" label="Login" onClick={(e) => {fncSubmit(e)}}></Button>
                        <span>
                            Don’t have an account?<button className="p-link" onClick={fncRegister}>Sign-up here</button>
                        </span>
                    </div>
                </div>

                <div className="login-footer flex align-items-center">
                    <div className="flex align-items-center login-footer-logo-container">
						<img src={`assets/highschools/` + props.schoolTheme + `/layout/images/logo-gray.png`} className="footer-logo mr-2" alt="exception-logo" />
						{props.schoolDetails.displayName}
                    </div>
                    <span>Copyright 2023</span>
                </div>
            </div>
        </div>
    );
};
