import React, { useState } from 'react';
import SEO  from '../components/SEO'
import { Button } from 'primereact/button'
import { useNavigate } from 'react-router-dom';

import ComingSoon from '../modals/comingSoon'

const Dashboard = (props) => {
    const navigate = useNavigate();
	const [comingSoonDisplay, setComingSoonDisplay] = useState(false);

	const fncAddContent = () => {

		setComingSoonDisplay(true)
	}
	
	return (
        <div className="grid dashboard-page" >
			<SEO 
			 	title={props.schoolDetails.abbreviation + " Dashboard"}
				description={props.schoolDetails.displayName + " Dashboard"} 
			 />	
            <div className="col-12">
                <div className="card">
					<div className="card flex-wrap flex justify-content-between">
                   		<h4>{props.schoolDetails.displayName} Sports</h4>
						<div>
							<Button label="Add Content" icon="pi pi-plus" className="p-button mr-2 mb-2" onClick={fncAddContent} />
						</div>
					 </div>
					<div className="grid">
						<div className="col-12 xl:col-8">
							<div className="card flex-wrap flex justify-content-between">
								<div className="flex flex-column dashboard-photocard">
									<div className="dashboard-photocard-card link" onClick={(e) => navigate('/girls-volleyball')}>
										<div>
											<img src={`assets/highschools/${props.schoolDetails.key}/layout/images/dashboard-1.jpg`} alt="logo" />
										</div>
									</div>
									<div className="dashboard-photocard-card link" onClick={(e) => navigate('/baseball')}>
										<div>
											<img src={`assets/highschools/${props.schoolDetails.key}/layout/images/dashboard-2.jpg`} alt="logo" />
										</div>
									</div>
									<div className="dashboard-photocard-card link" onClick={(e) => navigate('/1988/girls-basketball')}>
										<div>
											<img src={`assets/highschools/${props.schoolDetails.key}/layout/images/dashboard-3.jpg`} alt="logo" />
										</div>
									</div>
								</div>
								<div className="flex flex-column  dashboard-photocard-right">
									<div className="mb-2 link" onClick={(e) => navigate('/1993/football')}>
										<img src={`assets/highschools/${props.schoolDetails.key}/uploaded-photos/1993/football/main-93football.jpg`} alt="Linesville High School 1993 Football team" />
									</div>
									<div className="dashboard-photocard-right2 link" onClick={(e) => navigate('/1975/football')}>
										<img src={`assets/highschools/${props.schoolDetails.key}/uploaded-photos/1975/football/1.jpg`} alt="Linesville High School 1975 Football team" />	
									</div>
								</div>
								
							</div>
							
						</div>
						<div className="col-12 xl:col-4">
							<div className="card update-card">
								<h5>Latest Updates</h5>
								<div className="flex flex-column">
									<div className="flex justify-content-between mb-3 link" onClick={(e) => navigate('/1975/football')}>
										<div>New Page Added</div>
										<div className="flex flex-column">
											<div className="mainColor boldText">1975 Football</div>
											<div className="mainColor">2 Photos</div>
										</div>
									</div>
									<div className="flex justify-content-between mb-3 link" onClick={(e) => navigate('/1988/softball')}>
										<div>New Page Added</div>
										<div className="flex flex-column">
											<div className="mainColor boldText">1988 Softball</div>
											<div className="mainColor">5 Photos</div>
										</div>
									</div>
									<div className="flex justify-content-between mb-3 link" onClick={(e) => navigate('/1988/football')}>
										<div>New Page Added</div>
										<div className="flex flex-column">
											<div className="mainColor boldText">1988 Football</div>
											<div className="mainColor">11 Photos</div>
										</div>
									</div>
									<div className="flex justify-content-between mb-3 link" onClick={(e) => navigate('/1993/football')}>
										<div>New Videos Added</div>
										<div>
											<div className="mainColor boldText">1993 Linesville Cambridge Full Game</div>
										</div>
									</div>
									<div className="flex justify-content-between mb-3 link" onClick={(e) => navigate('/1994/football')}>
										<div>New Page Added</div>
										<div className="flex flex-column">
											<div className="mainColor boldText">1994 Football</div>
											<div className="mainColor">2 Videos / 20 Photos</div>
										</div>
									</div>
									
								
								</div>
								
							</div>
							{/*}
								<div className="card featured-card">
									<h6>Featured Pages</h6>
									
								</div>
							{*/}
						</div>
					</div>
                </div>
            </div>
			<ComingSoon 
				comingSoonDisplay={comingSoonDisplay} 
				setComingSoonDisplay={setComingSoonDisplay}
				comingSoonType="content"
			/>
        </div>
    );
};

export default Dashboard;
