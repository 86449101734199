import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import SEO  from '../components/SEO'
import PagesService from '../service/PagesService';	
import {sportsMapping} from '../constants/SPORTS' 
import PreviewCard from '../components/previewCard'


const SportPage = (props) => {
    const { sport } = props;
	const Pages = new PagesService();
	const [years, setYears] = useState([]);
	const navigate = useNavigate();
	
	useEffect(() => {
        fncGetYears(sport)
    }, [sport]);

	const fncGetYears = async (sport) => {
		let _years = await Pages.getYearsBySport(sport)
		if (_years.length === 0)  navigate('/notfound')
		setYears(_years)
	}

	const sportDetail = sportsMapping[sport] || {}
	
	return (
        <div className="grid">
			<SEO 
			 	title={props.schoolDetails.abbreviation + " Sport Page"}
				description={props.schoolDetails.displayName + " Sport Page"} 
			 />	
            <div className="col-12">
                <div className="card">
				<h5>{props.schoolDetails.displayName} - {sportDetail.displayName}</h5>
					<div className="grid ">
						{years.map((curYear) => {
							const objPageDetails = curYear.pageDetails || {}
							
							let teamImage = `assets/highschools/photoNotAvailable.jpg`
							
							if (curYear.mainPhoto !== undefined) {
								teamImage = `assets/highschools/${props.schoolTheme}/uploaded-photos/${curYear.year}/${curYear.sport}/${curYear.mainPhoto}`
							}
							
							return (
								<div className="col-12 xl:col-4 md:col-6">
									<PreviewCard 
										imagePath={teamImage}
										displayName={curYear.year}
										details={objPageDetails}
										link={'/' + curYear.year + '/' + curYear.sport}
										videoCount={curYear.videos.length}
										photoCount={curYear.photos.length}
									/>
									
								</div>
							)
						})}
					</div>
                </div>
            </div>
        </div>
    );
};

export default SportPage;
