import React, { useState, useEffect, useRef } from 'react';
import { classNames } from 'primereact/utils';
import { Route, Routes, useLocation } from 'react-router-dom';

import AppTopbar from './AppTopbar';
import AppFooter from './AppFooter';
import AppConfig from './AppConfig';
import AppBreadcrumb from './AppBreadcrumb';
import AppMenu from './AppMenu';

import Help from './pages/Help';
import EmptyPage from './pages/EmptyPage';
import Dashboard from './pages/Dashboard';
import PageType from './pages/PageType'

import PrimeReact from 'primereact/api';
import { Tooltip } from 'primereact/tooltip';

import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import './App.scss';
import AuthService from './service/AuthService';	
import PagesService from './service/PagesService';	

import {sportsMapping} from './constants/SPORTS' 

const App = (props) => {
    const Auth = new AuthService();
	const Pages = new PagesService();
	let isLoggedIn = Auth.loggedIn()

	const [configActive, setConfigActive] = useState(false);
    const [menuMode, setMenuMode] = useState('static');
    const [overlayMenuActive, setOverlayMenuActive] = useState(false);
    const [ripple, setRipple] = useState(true);
    const [sidebarStatic, setSidebarStatic] = useState(false);
    const [staticMenuDesktopInactive, setStaticMenuDesktopInactive] = useState(false);
    const [staticMenuMobileActive, setStaticMenuMobileActive] = useState(false);
    const [menuActive, setMenuActive] = useState(false);
    const [searchActive, setSearchActive] = useState(false);
    const [topbarMenuActive, setTopbarMenuActive] = useState(false);
    const [sidebarActive, setSidebarActive] = useState(false);
    const [pinActive, setPinActive] = useState(false);
    const [activeInlineProfile, setActiveInlineProfile] = useState(false);
    const [resetActiveIndex, setResetActiveIndex] = useState(null);

	const [menuYears, setMenuYears] = useState([]);
	const [menuSports, setMenuSports] = useState([]);
	const [routes, setRoutes] = useState([])

    const copyTooltipRef = useRef();
    const location = useLocation();

    PrimeReact.ripple = true;

	useEffect(() => {
        fncGetMenuItems()
    }, []);

	const default_routes = [
        { parent: 'Pages', label: 'Login' },
        { parent: 'Pages', label: 'Help' },
        { parent: 'Pages', label: 'Empty' },
        { parent: 'Pages', label: 'Access' }
    ];

	const fncGetMenuItems = async () => {
		let _routes = [...default_routes]
		let _menuYears = await Pages.getYears()
		setMenuYears(_menuYears.map(cur => {
			
			_routes.push({
				parent: 'Year',
				label: cur
			})
			
			return {
				label: cur,
				to: cur
			}
		}).sort((a,b) => (a.label > b.label?1:-1)))

		let _menuSports = await Pages.getSports()
		setMenuSports(_menuSports.reduce((acc,cur) => {
			let objSportMapping = sportsMapping[cur.sport] || {}
			
			_routes.push({
				parent: 'Team',
				label: cur.year + ' ' + objSportMapping.displayName || cur.sport
			})
			
			if (!_routes.find((cur) => cur.label === objSportMapping.displayName)) {
				_routes.push({
					parent: 'Sport',
					label: objSportMapping.displayName || cur.sport
				})

				acc.push({
					label: objSportMapping.displayName || cur.sport,
					to: cur.sport,
					icon: objSportMapping.icon || ''
				})
			}

			return acc
		},[]))

		setRoutes(_routes)
	}

	let menu = [
        {
            label: 'Sports',
            icon: 'fa-solid fa-football',
            items: menuSports
        },
		{
            label: 'Graduation Year',
            icon: 'fa-regular fa-calendar-days',
            items: menuYears
        }
    ];

	/*
		,
		{
            label: 'Featured',
            icon: 'pi pi-star',
            items: [
                { label: 'Coach McCullough', icon: 'fa fa-user', to: '/coachMcCullough' },
				{ label: 'Coach Chesko', icon: 'fa fa-user', to: '/coachChesko' }
            ]
        }
	*/

    

    let configClick;
    let menuClick;
    let searchClick = false;
    let topbarItemClick;

    useEffect(() => {
        copyTooltipRef && copyTooltipRef.current && copyTooltipRef.current.updateTargetEvents();
    }, [location]);

    useEffect(() => {
        setResetActiveIndex(true);
        setMenuActive(false);
    }, [menuMode]);

    const onDocumentClick = () => {
        if (!searchClick && searchActive) {
            onSearchHide();
        }

        if (!topbarItemClick) {
            setTopbarMenuActive(false);
        }

        if (!menuClick) {
            if (isHorizontal() || isSlim()) {
                setMenuActive(false);
                setResetActiveIndex(true);
            }

            if (overlayMenuActive || staticMenuMobileActive) {
                setOverlayMenuActive(false);
                setStaticMenuMobileActive(false);
            }

            hideOverlayMenu();
            unblockBodyScroll();
        }

        if (configActive && !configClick) {
            setConfigActive(false);
        }

        topbarItemClick = false;
        menuClick = false;
        configClick = false;
        searchClick = false;
    };

    const onSearchHide = () => {
        setSearchActive(false);
        searchClick = false;
    };

    const onMenuModeChange = (menuMode) => {
        setMenuMode(menuMode);
        setOverlayMenuActive(false);
    };

    const onConfigClick = () => {
        configClick = true;
    };

    const onConfigButtonClick = (event) => {
        setConfigActive((prevState) => !prevState);
        configClick = true;
        event.preventDefault();
    };

    const onRippleChange = (e) => {
        PrimeReact.ripple = e.value;
        setRipple(e.value);
    };

    const onMenuButtonClick = (event) => {
        menuClick = true;

        if (isOverlay()) {
            setOverlayMenuActive((prevState) => !prevState);
        }

        if (isDesktop()) {
            setStaticMenuDesktopInactive((prevState) => !prevState);
        } else {
            setStaticMenuMobileActive((prevState) => !prevState);
        }

        event.preventDefault();
    };

    const hideOverlayMenu = () => {
        setOverlayMenuActive(false);
        setStaticMenuMobileActive(false);
    };

    const onTopbarItemClick = (event) => {
        topbarItemClick = true;
        setTopbarMenuActive((prevState) => !prevState);
        hideOverlayMenu();
        event.preventDefault();
    };

    const onToggleMenu = (event) => {
        menuClick = true;

        if (overlayMenuActive) {
            setOverlayMenuActive(false);
        }

        if (sidebarActive) {
            setSidebarStatic((prevState) => !prevState);
        }

        event.preventDefault();
    };

    const onSidebarMouseOver = () => {
        if (menuMode === 'sidebar' && !sidebarStatic) {
            setSidebarActive(isDesktop());
            setTimeout(() => {
                setPinActive(isDesktop());
            }, 200);
        }
    };

    const onSidebarMouseLeave = () => {
        if (menuMode === 'sidebar' && !sidebarStatic) {
            setTimeout(() => {
                setSidebarActive(false);
                setPinActive(false);
            }, 250);
        }
    };

    const onMenuClick = () => {
        menuClick = true;
    };

    const onChangeActiveInlineMenu = (event) => {
        setActiveInlineProfile((prevState) => !prevState);
        event.preventDefault();
    };

    const onRootMenuItemClick = () => {
        setMenuActive((prevState) => !prevState);
    };

    const onMenuItemClick = (event) => {
        if (!event.item.items) {
            hideOverlayMenu();
            setResetActiveIndex(true);
        }

        if (!event.item.items && (isHorizontal() || isSlim())) {
            setMenuActive(false);
        }
    };

    const isHorizontal = () => {
        return menuMode === 'horizontal';
    };

    const isSlim = () => {
        return menuMode === 'slim';
    };

    const isOverlay = () => {
        return menuMode === 'overlay';
    };

    const isDesktop = () => {
        return window.innerWidth > 991;
    };

    const onInputClick = () => {
        searchClick = true;
    };

    const breadcrumbClick = () => {
        searchClick = true;
        setSearchActive(true);
    };

    const unblockBodyScroll = () => {
        if (document.body.classList) {
            document.body.classList.remove('blocked-scroll');
        } else {
            document.body.className = document.body.className.replace(new RegExp('(^|\\b)' + 'blocked-scroll'.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
        }
    };

    const layoutClassName = classNames('layout-wrapper', {
        'layout-static': menuMode === 'static',
        'layout-overlay': menuMode === 'overlay',
        'layout-overlay-active': overlayMenuActive,
        'layout-slim': menuMode === 'slim',
        'layout-horizontal': menuMode === 'horizontal',
        'layout-active': menuActive,
        'layout-mobile-active': staticMenuMobileActive,
        'layout-sidebar': menuMode === 'sidebar',
        'layout-sidebar-static': menuMode === 'sidebar' && sidebarStatic,
        'layout-static-inactive': staticMenuDesktopInactive && menuMode === 'static',
        'p-ripple-disabled': !ripple
    });

    return (
        <div className={layoutClassName} onClick={onDocumentClick}>
            <Tooltip ref={copyTooltipRef} target=".block-action-copy" position="bottom" content="Copied to clipboard" event="focus" />

            <div className="layout-main">
                <AppTopbar
                    items={menu}
                    menuMode={menuMode}
                    colorScheme={props.colorScheme}
                    menuActive={menuActive}
                    topbarMenuActive={topbarMenuActive}
                    activeInlineProfile={activeInlineProfile}
                    onTopbarItemClick={onTopbarItemClick}
                    onMenuButtonClick={onMenuButtonClick}
                    onSidebarMouseOver={onSidebarMouseOver}
                    onSidebarMouseLeave={onSidebarMouseLeave}
                    onToggleMenu={onToggleMenu}
                    onChangeActiveInlineMenu={onChangeActiveInlineMenu}
                    onMenuClick={onMenuClick}
                    onMenuItemClick={onMenuItemClick}
                    onRootMenuItemClick={onRootMenuItemClick}
                    resetActiveIndex={resetActiveIndex}
					schoolTheme={props.schoolTheme}
					schoolDetails={props.schoolDetails}
                />

                <AppMenu
                    model={menu}
                    onRootMenuItemClick={onRootMenuItemClick}
                    onMenuItemClick={onMenuItemClick}
                    onToggleMenu={onToggleMenu}
                    onMenuClick={onMenuClick}
                    menuMode={menuMode}
                    colorScheme={props.colorScheme}
                    menuActive={menuActive}
                    sidebarActive={sidebarActive}
                    sidebarStatic={sidebarStatic}
                    pinActive={pinActive}
                    onSidebarMouseLeave={onSidebarMouseLeave}
                    onSidebarMouseOver={onSidebarMouseOver}
                    activeInlineProfile={activeInlineProfile}
                    onChangeActiveInlineMenu={onChangeActiveInlineMenu}
                    resetActiveIndex={resetActiveIndex}
					schoolTheme={props.schoolTheme}
					schoolDetails={props.schoolDetails}
					isLoggedIn={isLoggedIn}
                />

                <AppBreadcrumb routes={routes} onMenuButtonClick={onMenuButtonClick} menuMode={menuMode} onInputClick={onInputClick} searchActive={searchActive} breadcrumbClick={breadcrumbClick} />

                <div className="layout-main-content">
                    <Routes>
                        <Route path="/" exact="true" element={<Dashboard schoolDetails={props.schoolDetails} />} />
                        <Route path="/help" element={<Help schoolDetails={props.schoolDetails} />} />
                        <Route path="/empty" element={<EmptyPage schoolDetails={props.schoolDetails} />} />
						<Route path="/:sportOrYear" element={<PageType schoolTheme={props.schoolTheme}  schoolDetails={props.schoolDetails} />} />
						<Route path="/:sportOrYear/:sportOrYear2" element={<PageType schoolTheme={props.schoolTheme}  schoolDetails={props.schoolDetails} />} />
						
						
                    </Routes>
                </div>

                <AppFooter colorScheme={props.colorScheme} schoolTheme={props.schoolTheme} schoolDetails={props.schoolDetails}  />
            </div>
			{/*}
				<AppConfig
					configActive={configActive}
					onConfigButtonClick={onConfigButtonClick}
					onConfigClick={onConfigClick}
					menuMode={menuMode}
					changeMenuMode={onMenuModeChange}
					colorScheme={props.colorScheme}
					changeColorScheme={props.onColorSchemeChange}
					changeSchool={props.onSchoolChange}
					schoolTheme={props.schoolTheme}
					ripple={ripple}
					onRippleChange={onRippleChange}
				/>
			{*/}
        </div>
    );
};

export default App;
