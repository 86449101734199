export const GET_USER_AUTH = "/api/auth/authenticate"
export const REGISTER_USER = "/api/auth/register"
export const GET_REGISTER_INFO = "/api/auth/getRegisterInfo"
export const REGISTER_USER_CONFIRM = "/api/auth/registerConfirm"

export const GET_YEARS = "/api/pages/getYears"
export const GET_SPORTS = "/api/pages/getSports"
export const GET_YEARS_BY_SPORT = "/api/pages/getYearsBySport"
export const GET_SPORTS_BY_YEAR = "/api/pages/getSportsByYear"
export const GET_TEAM = "/api/pages/getTeam"

export const TEST_ROUTE = "/api/test"