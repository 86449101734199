import React, { useState, useEffect } from 'react';
import SEO  from '../components/SEO'
import ImageGallery from 'react-image-gallery';
import ReactPlayer from 'react-player'
import Slider from "react-slick";
import { Image } from 'primereact/image';

import PagesService from '../service/PagesService';	
import {sportsMapping} from '../constants/SPORTS' 
import TeamSchedule from '../components/teamSchedule'



const TeamPage = (props) => {
    const { year, sport } = props;
	const Pages = new PagesService();
	const [team, setTeam] = useState({});
	const [pageLoaded, setPageLoaded] = useState(false);
	const [photos, setPhotos] = useState([]);
	const [videos, setVideos] = useState([]);
	
	useEffect(() => {
        fncGetTeam(year, sport)
    }, [year, sport]);
	
	const fncGetTeam = async (year, sport) => {
		let _team = await Pages.getTeam(year, sport)
		setTeam(_team)
		setPageLoaded(true)

		let _photos = _team.photos.reduce((acc,cur) => {
			if (!cur.isMain) {
				acc.push({
					original: `assets/highschools/${props.schoolTheme}/uploaded-photos/${year}/${sport}/${cur.imageName}`
				})
			}
			return acc
		},[])

		setPhotos(_photos)

		let _videos = _team.videos.map((cur) => {
			return {
				url: cur.videoName,
				title: cur.videoTitle
			}
		})

		setVideos(_videos)
	}

	const settings = {
		dots: true,
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1
	  };

	  let teamImage = `assets/highschools/photoNotAvailable.jpg`
							
		if (team.mainPhoto !== undefined) {
			teamImage = `assets/highschools/${props.schoolTheme}/uploaded-photos/${year}/${sport}/${team.mainPhoto}`
		}

	return (
		<>
			{pageLoaded && 
				<div className="card grid teamCard">
					<SEO 
						title={props.schoolDetails.abbreviation + " " + year + " " + sportsMapping[sport].displayName}
						description={props.schoolDetails.displayName + " " + year + " " + sportsMapping[sport].displayName} 
					/>	
					<div className="col-12 flex flex-column md:flex-row md:justify-content-between">
						<div className="flex flex-column">
							<h4>{props.schoolDetails.displayName} - {year} - {sportsMapping[sport].displayName} </h4>
						</div>
							
						<div>
							{team.pageDetails.nickName && 
								<h5 className="mainColor">
									{team.pageDetails.nickName}
								</h5>
							}
						</div>
					</div>		

					<div className="col-12 md:col-6">
						<img src={teamImage} />
					</div>
					<div className="col-12 md:col-6 xl:col-3">
						<div className="card">
							{team.pageDetails.record && 
								<h5>
									Record: {team.pageDetails.record}
								</h5>
							}
							
							<h5 className="mainColor">Schedule</h5>
							<TeamSchedule 
								schedule={team.pageDetails.schedule}
							/>
						</div>
					</div>
					<div className="col-12 xl:col-3 grid">
						{team.videos.length > 0 && 
							<div className="col-12">
								<div className="card pb-4">
									<h5 className="mainColor">Videos</h5>
									<Slider {...settings}>
										{videos.map((cur,index) => {
											return (
												<div className="flex flex-column  ">
													<div className="mb-2 align-self-center">{cur.title}</div>
													<div className='player-wrapper ' key={"currentVideo_" + index}>
														
														<ReactPlayer
															url={cur.url}
															config={{
																youtube: {
																playerVars: { showinfo: 1 }
																}
															}}
															className='react-player'
															width='100%'
															height='100%'
														/>
													
													</div>
												</div>
												
											)
										})}
									</Slider>
									
								</div>
							</div>
						}
						{team.photos.length > 0 && 
							<div className="col-12">
								<div className="card">
									<h5 className="mainColor">Photos</h5>
									<Slider {...settings}>
									{photos.map((cur,index) => {
										return (
											<div>
												<Image src={cur.original} alt="Image" width="250" height="350" preview />					
											</div>
										)
									})}
									</Slider>
									
								</div>
							</div>
						}
						{/*}
						{team.photos.length > 0 && 
							<div className="col-12">
								<div className="card">
									<h5 className="mainColor">Photos</h5>
									<div>
										<ImageGallery 
											items={photos} 
											thumbnailHeight="30px"
										/>
									</div>
									
								</div>
							</div>
						}
						{*/}
					</div>
				</div>
			}
		</>
       
    );
};

export default TeamPage;
