import React from 'react';

import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';

let ComingSoon = (props) => {
	
	const {comingSoonDisplay, setComingSoonDisplay, comingSoonType}  = props
	

	const renderFooter = () => {
        return (
            <div>
                <Button label="OK" icon="pi pi-check" onClick={() => setComingSoonDisplay(false)} autoFocus />
            </div>
        );
    }

	return (
		<>
		{comingSoonType === 'login' && 
			<Dialog header="User Registration - Coming Soon" visible={comingSoonDisplay} className="comingSoonDialog" footer={renderFooter()} onHide={() => setComingSoonDisplay(false)}>
				<div>
					Registration functionality is coming soon. This will give you the ability to register an email and add content/comments to the site.
				</div>	
			</Dialog>
		}
		{comingSoonType === 'content' && 
			<Dialog header="Add New Content - Coming Soon" visible={comingSoonDisplay} className="comingSoonDialog" footer={renderFooter()} onHide={() => setComingSoonDisplay(false)}>
				<div className="flex flex-column">
					<div>
						Adding Content will be added shortly and will enable registered users to upload new photos, link to youtube videos, or even add new pages. 
					</div>	
					<div className="mt-4">
						If you have content that you think could be added, feel free to email <a href="mailto:info@linesvillesports.com">info@linesvillesports.com</a>.
					</div>
				</div>
			</Dialog>
		}
		</>
	)

}


export default ComingSoon;