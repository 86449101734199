import axios from 'axios';
import decode from 'jwt-decode';
import { GET_USER_AUTH, TEST_ROUTE, REGISTER_USER, GET_REGISTER_INFO, REGISTER_USER_CONFIRM} from '../constants/URLS';


export default class AuthService {
	test = async () => {
		
		let testResults = await axios({
			method: 'get',
			url: TEST_ROUTE
		})
	}

	login = async (username, password, school) => {
		try {
			let results = await axios({
				method: 'post',
				url: GET_USER_AUTH,
				data: {
					username: username,
					password: password,
					school: school
				}
			})

			if (!results.data.success){
				return false
			} else {
				axios.defaults.headers.common['Authorization'] = results.data.token;
				this.setToken(results.data.token) // Setting the token in localStorage
				return true
			}
		} catch (err)  {
			return false
		}
		
	}

	register = async (email) => {
		try {
			let results = await axios({
				method: 'post',
				url: REGISTER_USER,
				data: {
					email: email
				}
			})
			return results.data
		} catch (err)  {
			console.log(err)
			return false
		}
	}

	registerConfirm = async (userDetails) => {
		try {
			let results = await axios({
				method: 'post',
				url: REGISTER_USER_CONFIRM,
				data: {...userDetails}
				
			})
			return results.data.success
		} catch (err)  {
			console.log(err)
			return false
		}
	}

	

	getRegisterInfo = async (registerHash) => {
		try {
			let results = await axios({
				method: 'post',
				url: GET_REGISTER_INFO,
				data: {
					registerHash: registerHash
				}
			})

			return results.data
		} catch (err)  {
			return {success: false}
		}
	}

	loggedIn() {
		// Checks if there is a saved token and it's still valid
		const token = this.getToken() // Getting token from localstorage
		axios.defaults.headers.common['Authorization'] = token;
		return !!token && !this.isTokenExpired(token) // handwaiving here
	}
	isTokenExpired(token) {
		try {
			const decoded = decode(token);
			if (decoded.exp < Date.now() / 1000) { // Checking if token is expired.
				return true;
			}
			else
				return false;
		}
		catch (err) {
			return false;
		}
	}
	setToken(idToken) {
		// Saves user token to localStorage
		localStorage.setItem('id_token', idToken)
		//var objProfile = this.getProfile()
	}
	getToken = () => {
		// Retrieves the user token from localStorage
		return localStorage.getItem('id_token')
	}
	logout() {
		// Clear user token and profile data from localStorage
		localStorage.removeItem('id_token');
	}
	getProfile = () => {
		// Using jwt-decode npm package to decode the token
		return decode(this.getToken());
	}
}