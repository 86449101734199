import React from 'react';
import { useNavigate } from 'react-router-dom';

export default function PreviewCard(props) {
	const navigate = useNavigate();
	
	
	return (
		<div className="p-2 card previewCard" onClick={(e) => navigate(props.link)}>
			<div className="grid justify-content-between">
				<div className="col-6">
					<img src={props.imagePath} />
				</div>
				<div className="col-6 flex flex-column" >
					<h4>{props.displayName}</h4>
					{props.details.nickName && 
						<div className="mainColor">
							{props.details.nickName}
						</div>
					}
					<div>
						{props.details.record}
					</div>
					{props.details.extraData && 
						<div>
							{props.details.extraData}
						</div>
					}
					<div>
						{props.videoCount} Videos / {props.photoCount} Photos 
					</div>
				</div>
			</div>
		</div>
	)
}