import React, { useState, useRef } from 'react';
import { CSSTransition } from 'react-transition-group';
import { classNames } from 'primereact/utils';
import AuthService from './service/AuthService';
import { useNavigate } from 'react-router-dom';
import { Button } from 'primereact/button';

import ComingSoon from './modals/comingSoon'

const AppInlineMenu = (props) => {
    const menuRef = useRef(null);
	const Auth = new AuthService();
	const navigate = useNavigate();
	const [comingSoonDisplay, setComingSoonDisplay] = useState(false);
    const isSlim = () => {
        return props.menuMode === 'slim';
    };

    const isStatic = () => {
        return props.menuMode === 'static';
    };

    const isSidebar = () => {
        return props.menuMode === 'sidebar';
    };

    const isMobile = () => {
        return window.innerWidth <= 991;
    };

	const fncLogin = () => {

		setComingSoonDisplay(true)
		//navigate('/login')
	}

    return (
        <>
            {!isMobile() && (isStatic() || isSlim() || isSidebar()) && (
                <div className={classNames('layout-inline-menu', { 'layout-inline-menu-active': props.activeInlineProfile })}>
                    {props.isLoggedIn && 
						<>
							<button className="layout-inline-menu-action p-link" onClick={props.onChangeActiveInlineMenu}>
								<img src={"assets/highschools/" + props.schoolTheme + "/layout/images/profile-image.png"} alt="avatar" style={{ width: '44px', height: '44px' }} />
								<span className="layout-inline-menu-text">Ken York</span>
								<i className="layout-inline-menu-icon pi pi-angle-down"></i>
							</button>
							<CSSTransition nodeRef={menuRef} classNames="p-toggleable-content" timeout={{ enter: 1000, exit: 450 }} in={props.activeInlineProfile} unmountOnExit>
								<ul ref={menuRef} className="layout-inline-menu-action-panel">
									<li className="layout-inline-menu-action-item">
										<button className="p-link" onClick={Auth.logout}>
											<i className="pi pi-power-off pi-fw"></i>
											<span>Logout</span>
										</button>
									</li>
									<li className="layout-inline-menu-action-item">
										<button className="p-link">
											<i className="pi pi-cog pi-fw"></i>
											<span>Settings</span>
										</button>
									</li>
									<li className="layout-inline-menu-action-item">
										<button className="p-link">
											<i className="pi pi-user pi-fw"></i>
											<span>Profile</span>
										</button>
									</li>
								</ul>
							</CSSTransition>
						</>	
					}
					{!props.isLoggedIn && 
						<div className="ml-2">
							<Button type="button" label="Login" style={{width:"90%"}} onClick={fncLogin}></Button>
						</div>
						
					}
					<ComingSoon 
						comingSoonDisplay={comingSoonDisplay} 
						setComingSoonDisplay={setComingSoonDisplay}
						comingSoonType="login"
					/>
                </div>
            )}
        </>
    );
};

export default AppInlineMenu;
