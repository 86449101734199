export const sportsMapping = {
	"football": {
		displayName: "Football",
		icon: "fa-solid fa-football"
	},
	"boys-basketball": {
		displayName: "Boys Basketball",
		icon: "fa-solid fa-basketball"
	},
	"girls-basketball": {
		displayName: "Girls Basketball",
		icon: "fa-solid fa-basketball"
	},
	"girls-volleyball": {
		displayName: "Girls Volleyball",
		icon: "fa-solid fa-volleyball"
	},
	"cross-country": {
		displayName: "Cross Country",
		icon: "fa-solid fa-running"
	},
	"baseball": {
		displayName: "Baseball",
		icon: "fa-solid fa-baseball"
	},
	"softball": {
		displayName: "Softball",
		icon: "fa-solid fa-baseball"
	}
}