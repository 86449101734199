import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import SEO  from '../components/SEO'
import PagesService from '../service/PagesService';	
import {sportsMapping} from '../constants/SPORTS' 
import PreviewCard from '../components/previewCard'

const YearPage = (props) => {
    const navigate = useNavigate();
	const { year } = props;
	const Pages = new PagesService();
	const [sports, setSports] = useState([]);
	
	
	useEffect(() => {
        fncGetSports(year)
    }, [year]);

	const fncGetSports = async (year) => {
		let _sports = await Pages.getSportsByYear(year)
		if (_sports.length === 0)  navigate('/notfound')
		setSports(_sports)
	}

	return (
        <div className="grid">
			<SEO 
			 	title={props.schoolDetails.abbreviation + " Year Page"}
				description={props.schoolDetails.displayName + " Year Page"} 
			 />	
            <div className="col-12">
                <div className="card">
                    <h5>{props.schoolDetails.displayName} - {year}</h5>
					<div className="grid ">
						{sports.map((curSport) => {
							const objSportMapping = sportsMapping[curSport.pageName] || {}
							const objPageDetails = curSport.pageDetails || {}
							
							let teamImage = `assets/highschools/photoNotAvailable.jpg`
							
							if (curSport.mainPhoto !== undefined) {
								teamImage = `assets/highschools/${props.schoolTheme}/uploaded-photos/${curSport.year}/${curSport.sport}/${curSport.mainPhoto}`
							}

							return (
								<div className="col-12 xl:col-4 md:col-6">
									<PreviewCard 
										imagePath={teamImage}
										displayName={objSportMapping.displayName}
										details={objPageDetails}
										link={'/' + curSport.year + '/' + curSport.sport}
										videoCount={curSport.videos.length}
										photoCount={curSport.photos.length}
									/>
								</div>
							)
						})}
					</div>
                    
                </div>
            </div>
        </div>
    );
};

export default YearPage;
